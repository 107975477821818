<template>
  <section class="manual-authentication-wrapper flex-column">
    <component
      :is="headerComponent"
      current-modal-name="authentication-manual-flow-modal"
      previous-modal-name="authentication-scan-code-modal"
      is-custom-close-handler
      @close-modal="onCloseModal"
    >
      <span slot="header-title" class="title-text">
        {{ $t('dashboard.label.twoFactorAuthentication') }}
      </span>
    </component>
    <div class="manual-authentication">
      <div class="manual-authentication__title">{{ $t('dashboard.label.cantScan') }}</div>
      <div class="manual-authentication__steps">
        <ol class="manual-authentication__steps-list">
          <li>
            {{ $t('dashboard.authentication.openApp') }}
          </li>
          <li>{{ $t('dashboard.authentication.typeFullEmailAddres') }}</li>
          <li>
            <i18n path="dashboard.authentication.typeTheKey" tag="div">
              <div class="bold">{{ authenticationCode.secretCode }}</div>
            </i18n>
          </li>
          <li>{{ $t('dashboard.authentication.setupTheKey') }}</li>
          <li>{{ $t('dashboard.authentication.tapAdd') }}</li>
        </ol>
      </div>

      <div class="manual-authentication__description-main mb20">
        {{ $t('dashboard.label.EnterSixDigitCode') }}
      </div>

      <div class="manual-authentication__description-secondary description-secondary">
        {{ $t('dashboard.authentication.afterEntering') }}
      </div>
      <input
        ref="input"
        v-allow-only-reg-exp="/^([0-9])$/"
        maxlength="12"
        :value="formattedVerificationCode"
        class="form-control manual-authentication__input"
        @input="onAuthenticationCodeInput"
      />

      <div v-if="hasInvalidVerificationCode" class="manual-authentication__error-message">
        <span>{{ $t('dashboard.authentication.twoFactorAuthenticationFailed') }}</span>
        <span>{{ $t('dashboard.authentication.pleaseTryAgain') }}</span>
      </div>
    </div>
    <footer class="manual-authentication__footer footer ">
      <div class="divider"></div>
      <div class="footer__button-container flex--center--center">
        <button
          class="manual-authentication__action-button rounded-button-white"
          @click="enableTwoFactorAuthentication"
        >
          {{ $t('dashboard.label.enable') }}
        </button>
      </div>
    </footer>
    <authentication-confirm-modal />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import AuthenticationConfirmModal from '@/modules/dashboard/components/two-factor-authentication/AuthenticationConfirmModal';

import { confirmTwoFactorAuthenticationSetup } from '@/api';

import { transformVerificationCode, deleteSpaces, isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';

export default {
  name: 'TwoFactorAuthenticationManualFlow',
  components: { AuthenticationConfirmModal },
  props: {
    isHeaderWithBackButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      verificationCode: '',
      hasInvalidVerificationCode: false
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      authenticationCode: rootTypes.getters.GET_USER_AUTHENTICATION_CODE
    }),
    formattedVerificationCode() {
      return transformVerificationCode(this.verificationCode);
    },
    headerComponent() {
      return this.isHeaderWithBackButton ? ModalHeaderWithPrevious : ModalHeader;
    }
  },
  methods: {
    ...mapActions({
      getUpdatedUser: rootTypes.actions.UPDATE_USER
    }),
    onCloseModal() {
      if (isMobileDevice()) {
        this.$router.push({ name: 'UserProfile' });

        return;
      }

      this.$modal.hide('authentication-manual-flow-modal');
    },
    async enableTwoFactorAuthentication() {
      const {
        data: { successful }
      } = await confirmTwoFactorAuthenticationSetup(this.userId, {
        confirmationCode: deleteSpaces(this.verificationCode)
      });

      if (!successful) {
        this.hasInvalidVerificationCode = true;

        return;
      }

      await this.getUpdatedUser(this.userId);

      if (!isMobileDevice()) {
        this.onCloseModal();
      }

      this.$modal.show('authentication-confirm-modal');
    },
    onAuthenticationCodeInput(event) {
      this.verificationCode = get(event, ['target', 'value']);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/inputs';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/common/text';

.manual-authentication-wrapper {
  height: 100%;
}

.manual-authentication {
  flex: 1;
  width: 470px;
  margin: 34px auto 10px;
  text-align: center;

  &__title {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $main-title-color;
  }

  &__description-main {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: $text-color;
  }

  &__description-secondary {
    width: 300px;
    margin: 0 auto;
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: $text-color;
  }

  &__steps {
    width: 500px;

    margin: 20px auto;
    border: 1px dashed $border-light-grey-color;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    border-radius: 8px;
    background-color: $background;

    &-list {
      width: 460px;
      margin: 0 auto;
      padding: 20px;
      & li:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__link {
    color: #526d9f;
  }

  &__input {
    width: 100px;
    height: auto;
    padding: 10px;
    margin: 20px auto;

    border: 1px solid $light-blue;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    line-height: 17px;
    color: $main-title-color;
  }

  &__action-button {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    border: none;
    background-color: $hovered-button-color;

    &:hover {
      background-color: $hovered-button-blue-color;
      border: none;
    }
  }

  &__error-message {
    font-size: 15px;
    line-height: 17px;
    color: $error-color;
  }
}

.code {
  display: block;
  font-weight: 700;
}

.description-secondary {
  width: 300px;
}

.footer {
  height: 75px;

  &__button-container {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .manual-authentication {
    width: 330px;
    margin-bottom: 110px;

    &__steps {
      width: 330px;

      &-list {
        width: 290px;
        margin: 0 auto;
        padding: 20px;
        & li:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }

    &__error-message {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
