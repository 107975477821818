<template>
  <modal
    name="authentication-confirm-modal"
    :classes="['modal-window', { 'mobile-fullscreen-modal': isMobile }]"
    transition="scale"
    :click-to-close="false"
    :width="modalWidth"
    :height="modalHeight"
  >
    <div class="modal-container">
      <div class="authentication-confirmation">
        <div class="authentication-confirmation__content content">
          <span class="content__icon icon-shield-check"></span>
          <span class="content__text content__text--green">{{
            $t('dashboard.securitySettings.authenticationEnabled')
          }}</span>
        </div>
        <div class="authentication-confirmation__actions">
          <div class="actions-buttons flex--center--center">
            <button class="rounded-button-white button-ok" @click="onClose">
              {{ $t('dashboard.button.ok') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'AuthenticationConfirmModal',
  data() {
    return {
      modalWidth: '500px',
      modalHeight: '265px',
      isMobile: isMobileDevice()
    };
  },
  created() {
    if (this.isMobile) {
      this.modalWidth = '100%';
      this.modalHeight = '100%';
    }
  },
  methods: {
    onClose() {
      if (this.isMobile) {
        this.$router.push({ name: 'UserProfile' });
      }

      this.$modal.hide('authentication-confirm-modal');
    },
    disableAuthentication() {
      this.$emit('disable-authentication');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.authentication-confirmation {
  padding: 70px 70px 60px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 26px;
  }
}

.content {
  font-size: 16px;
  line-height: 19px;

  &__icon {
    width: 30px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
  }

  &__text {
    &--green {
      color: $green-color-success;
    }
  }
}
.actions-buttons {
  width: 260px;
  margin: 0 auto;
}

.button-ok {
  width: 80px;
  height: 36px;
  color: $text-color;
}

@media (max-width: 767px) {
  .modal-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: none;
  }

  .authentication-confirmation {
    padding: 0;
  }
}
</style>
